import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useJobContext} from '../context/job-context';

type Props = {
    proofId: number;
}
const ApproveButtonModal = ({proofId} : Props) => {
    const {approveJob} = useJobContext();
    const [open, setOpen] = React.useState(false);
    const [approvedBy, setApprovedBy] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleApprove = () => {
        if (approvedBy) {
            approveJob(proofId, approvedBy);
        }
    };

    return (
        <div>
            <Button
                variant="contained"
                onClick={handleClickOpen}
                sx={{mt: 1, color: 'white', display: 'inline', backgroundColor: 'green', width: '125px'}}
            >
                Approve
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Approve Proof</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To approve this proof, please enter your name and press approve.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="reason"
                        label="Approved By"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={approvedBy}
                        onChange={(e) => {
                            setApprovedBy(e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleApprove}>Approve</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ApproveButtonModal;
