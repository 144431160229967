import {Container} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import {useJobContext} from "../context/job-context";
import ApproveButtonModal from './ApproveButtonModal';
import DownloadButton from './DownloadButton';
import RejectButtonModal from './RejectButtonModal';

const ApproveProofs = () => {
    const {job} = useJobContext();

    if (job === null) {
        return <Box>Job not found.</Box>
    }
    
    const markdown = job.proofingTipsMarkdown;

    return (
        <Container maxWidth="lg">
            <Box sx={{mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography variant="h4" sx={{fontWeight: 800, color: 'red'}}>PRESENTING: Your Online Proof!</Typography>
                <Typography variant="body1" sx={{mt: 3, textAlign: 'center'}}>
                    Please check this proof CAREFULLY. Once the proof is approved, we are not responsible for any typographic or content errors.
                </Typography>
                <Typography variant="body1" sx={{textAlign: 'center'}}>
                    In order to ensure an accurate proofing experience, read our proofing tips below.
                </Typography>
            </Box>

            <Box sx={{mt: 3, fontWeight: 800, color: '#777777'}}>
                CLIENT: <Typography display="inline" variant="body1" sx={{fontWeight: 400, color: '#000000'}}>{job.clientName}</Typography>
            </Box>

            <Box sx={{mt: 1, fontWeight: 800, color: '#777777'}}>
                PROJECT: <Typography display="inline" variant="body1" sx={{fontWeight: 400, color: '#000000'}}>{job.projectName}</Typography>
            </Box>

            <Box sx={{mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-center'}}>
                <Typography display="inline" variant="body1" style={{fontWeight: 800}}>{job.message}</Typography>
            </Box>

            <Box sx={{
                border: '1px solid black',
                mt: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                m: '3px',
                p: '3px',
            }}>
                <Typography variant="body1" sx={{mt: 1, textAlign: 'center'}}>
                    Please note that your proof may have multiple pages and the images below are low-resolution thumbnails of your proof.
                </Typography>
                <Typography variant="body1" sx={{mt: 3, textAlign: 'center'}}>
                    For the most accurate proofing experience, please be sure to download the full PDF proof.
                </Typography>
                <Box sx={{
                    mt: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                }}>
                    {job && job.proofs.map((proof, index) => {
                        return <Box
                            key={`proof-${index}`}
                            sx={{
                                border: '1px solid black',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minWidth: {
                                    xs: '120px',
                                    sm: '220px',
                                },
                                m: '3px',
                                p: '3px',
                            }}>
                            <Box
                                component="img"
                                sx={{
                                    mt: 2,
                                    minHeight: {
                                        xs: '100px',
                                        sm: '200px',
                                    },
                                    maxWidth: '86vw',
                                }}
                                alt={`Proof image -${index}`}
                                src={proof.fileThumbnailBase64}
                            />
                            <Typography variant="body2">{proof.fileName}</Typography>
                            <ApproveButtonModal proofId={proof.id} />
                            <DownloadButton proofId={proof.id} />
                        </Box>
                    })}
                </Box>
                <Box sx={{mt: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <RejectButtonModal />
                </Box>
            </Box>

            <Typography variant="h5" sx={{mt: 3, fontWeight: 800, color: 'red', textAlign: 'center'}}>Thank you for proofing your Print Plus project online.</Typography>
            <Typography variant="body1" sx={{mt: 2, textAlign: 'center'}}>
                If you have any questions please email us or call (252) 441-6718.
            </Typography>

            {/* <form method="post" name="SubscribeForm" title="Subscribe To Our Newsletter" action="http://www.graphicmail.com/RWCode/subscribe.asp?SiteID=52842&Mode=subscribe&resize=0" target="_blank" >
                <Box sx={{mt: 3, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography variant="body1" sx={{mr: 2}}>Don't forget to join our mailing list for news and special offers:</Typography>
                    <TextField id="outlined-basic" label="email address" variant="outlined" />
                    <Button type="submit" variant='contained' sx={{ml: 2, color: 'white', display: 'inline'}}>Sign Up</Button>
                </Box>
            </form> */}

            <Box sx={{mt: 3, backgroundColor: '#ccc', p: 2, mb: {xs: 10, lg: 5}, textAlign: 'left'}} >
                <ReactMarkdown children={markdown} />
            </Box>
        </Container>
    );
}

export default ApproveProofs;
