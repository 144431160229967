import MenuIcon from '@mui/icons-material/Menu';
import {Link} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import {useJobContext} from '../context/job-context';

const Header = () => {
    const {job} = useJobContext();

    return <AppBar position="sticky">
        <Toolbar>
            <Box component="div" sx={{typography: 'h5', flexGrow: 1, fontWeight: 800}}>
                <Link href="http://myprintplus.com" variant="body2">
                    <Box
                        component="img"
                        sx={{
                            mt: 2,
                            height: {
                                xs: '40px',
                                sm: '60px',
                            },
                        }}
                        alt={`Print Plus Logo`}
                        src="/logo.png"
                    />
                </Link>
            </Box>

            <Box sx={{flexGrow: 0, display: {xs: 'none', md: 'flex'}}}>
                {job && job.navigationMenu && job.navigationMenu.map((navigationMenuItem, index) => {
                    return <Button
                        key={`header-${index}`}
                        sx={{my: 2, color: 'white', display: 'block'}}
                        href={navigationMenuItem.src}
                        target="_blank"
                    >{navigationMenuItem.title}</Button>
                })}
            </Box>
        </Toolbar>
    </AppBar>;
}

export default Header;
