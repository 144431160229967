import React from 'react';
import {Route, Routes} from 'react-router-dom';
import './App.css';
import Job from './pages/Job';

const App = () => {
    return <div className="App">
        <Routes>
            <Route path="/" element={<Job />} />
        </Routes>
    </div>
}

export default App;
