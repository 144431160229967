import {Container} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {useJobContext} from "../context/job-context";
import DownloadButton from './DownloadButton';

const ProofViewer = () => {
    const {job} = useJobContext();

    if (job === null) {
        return <Box>Job not found.</Box>
    }

    return (
        <Container maxWidth="lg">
            <Box sx={{mt: 3, fontWeight: 800, color: '#777777'}}>
                CLIENT: <Typography display="inline" variant="body1" sx={{fontWeight: 400, color: '#000000'}}>{job.clientName}</Typography>
            </Box>

            <Box sx={{mt: 1, fontWeight: 800, color: '#777777'}}>
                PROJECT: <Typography display="inline" variant="body1" sx={{fontWeight: 400, color: '#000000'}}>{job.projectName}</Typography>
            </Box>

            <Box sx={{mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-center'}}>
                <Typography display="inline" variant="body1" style={{fontWeight: 800}}>{job.message}</Typography>
            </Box>

            <Box sx={{
                border: '1px solid black',
                mt: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                m: '3px',
                p: '3px',
            }}>
                <Box sx={{
                    mt: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                }}>
                    {job && job.proofs.map((proof, index) => {
                        return <Box
                            key={`proof-${index}`}
                            sx={{
                                border: '1px solid black',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minWidth: {
                                    xs: '120px',
                                    sm: '220px',
                                },
                                m: '3px',
                                p: '3px',
                            }}>
                            <Box
                                component="img"
                                sx={{
                                    mt: 2,
                                    minHeight: {
                                        xs: '100px',
                                        sm: '200px',
                                    },
                                }}
                                alt={`Proof image -${index}`}
                                src={proof.fileThumbnailBase64}
                            />
                            <Typography variant="body2">{proof.fileName}</Typography>
                            <DownloadButton proofId={proof.id} />
                        </Box>
                    })}
                </Box>
            </Box>
        </Container>
    );
}

export default ProofViewer;
