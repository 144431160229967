import Box from '@mui/material/Box';
import React from 'react';
import JobDisplay from '../components/JobDisplay';
import {JobContextProvider} from '../context/job-context';

const Job = () => {
    return <Box sx={{flexGrow: 1}}>
        <JobContextProvider>
            <JobDisplay />
        </JobContextProvider>
    </Box>
}

export default Job;
