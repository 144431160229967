import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useJobContext} from '../context/job-context';

const RejectButtonModal = () => {
    const {job, rejectJob} = useJobContext();
    const [open, setOpen] = React.useState(false);
    const [rejectReason, setRejectReason] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleReject = () => {
        if (job && rejectReason) {
            rejectJob(job.proofs.map((proof) => proof.id), rejectReason);
        }
    };

    return (
        <div>
            <Button
                variant="contained"
                onClick={handleClickOpen}
                sx={{mb: 2, color: 'white', display: 'inline', backgroundColor: 'red', width: '125px'}}
            >
                Reject All
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Reject All Proofs</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To reject all proofs, please enter a reason and press reject.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="reason"
                        label="Reject Reason"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={rejectReason}
                        onChange={(e) => {
                            setRejectReason(e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleReject}>Reject</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default RejectButtonModal;
