import React, {useCallback, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

export type DownloadResponse = {
    id?: number;
    file?: string;
    'PRF_JOBS__Jobs::OLP_jobToken'?: string;
    error?: string;
}

export type Proof = {
    id: number;
    fileName: string;
    fileThumbnailBase64: string;
}

export type NavLink = {
    title: string;
    src: string;
}

export type Job = {
    id: number;
    clientName: string;
    projectName: string;
    status: string;
    message: string;
    footerArray: string[];
    proofs: Proof[];
    contactUsMarkdown: string;
    proofingTipsMarkdown: string;
    navigationMenu: NavLink[];
    homepageUrl: string;
    contactEmailAddress: string;
}

type JobContextInterface = {
    job: Job | null;
    loading: boolean;
    firstLoad: boolean;
    approveJob: (proofId: number, approvedBy: string) => void;
    rejectJob: (proofIds: number[], approvedBy: string) => void;
    signUp: (email: string) => void;
    downloadJob: (proofId: number) => Promise<DownloadResponse>;
};

type JobContextProviderProps = {children: React.ReactNode}

const JobContext = React.createContext<JobContextInterface>({} as JobContextInterface);

const useJobContext = (): JobContextInterface => {
    const context = React.useContext(JobContext)

    if (Object.keys(context).length === 0) {
        throw new Error('useJobContext must be used within a JobContextProvider')
    }

    return context
}

const JobContextProvider = ({children}: JobContextProviderProps): JSX.Element => {
    const [job, setJob] = useState<Job | null>(null);
    const [loading, setLoading] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [searchParams] = useSearchParams();
    let jobId = searchParams.get("job");

    const fetchJob = useCallback(async () => {
        setLoading(true);

        const response = await fetch(process.env.REACT_APP_PUBLIC_API_ENDPOINT + `/jobs/${jobId}`);
        const responseJson = await response.json();

        if (response.ok) {
            setJob(responseJson);
        }

        setLoading(false);
        setFirstLoad(false);
    }, [jobId])

    const approveJob = async (proofId: number, approvedBy: string) => {
        let init: RequestInit = {headers: new Headers({'Content-Type': 'application/json'}), method: 'POST'};
        init.body = JSON.stringify({
            approvedBy: approvedBy
        });

        const response = await fetch(
            process.env.REACT_APP_PUBLIC_API_ENDPOINT + `/jobs/${jobId}/proofs/${proofId}/accept`,
            init
        );

        if (response.ok) {
            fetchJob();
        }
    }

    const rejectJob = async (proofIds: number[], remarks: string) => {
        let init: RequestInit = {headers: new Headers({'Content-Type': 'application/json'}), method: 'POST'};
        init.body = JSON.stringify({
            proofIds,
            remarks: remarks,
        });

        const response = await fetch(process.env.REACT_APP_PUBLIC_API_ENDPOINT + `/jobs/${jobId}/reject-proofs`, init);

        if (response.ok) {
            fetchJob();
        }
    }

    const downloadJob = async (proofId: number): Promise<DownloadResponse> => {
        if (job) {
            const response = await fetch(
                process.env.REACT_APP_PUBLIC_API_ENDPOINT + `/jobs/${jobId}/proofs/${proofId}/download`
            );
            const responseJson = await response.json();

            if (response.ok) {
                return responseJson as DownloadResponse
            }
        }

        return {
            error: 'There was a problem downloading your proof.'
        }
    }

    const signUp = async () => {
        // const url = new URL('/jobs', process.env.PUBLIC_API_ENDPOINT);
        // const response = await fetch(url.toString());
        const response = await fetch('http://localhost:3000/jobtest.json');
        const responseJson = await response.json();
        setJob(responseJson);
    }

    useEffect(() => {
        if (jobId) {
            fetchJob()
        }
    }, [fetchJob, jobId])


    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = {
        job,
        firstLoad,
        loading,
        approveJob,
        rejectJob,
        signUp,
        downloadJob,
    }

    return (
        <JobContext.Provider value={value}>
            {children}
        </JobContext.Provider>
    )
}


export {JobContextProvider, useJobContext};
