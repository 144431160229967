import {CircularProgress} from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import {useJobContext} from '../context/job-context';
import ApproveProofs from './ApproveProofs';
import Footer from './Footer';
import Header from './Header';
import ProofViewer from './ProofViewer';

const JobDisplay = () => {
    const {job, loading} = useJobContext();

    if (loading) {
        return <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexGrow: 1}}><CircularProgress /></Box>
    }

    if (!job) {
        return <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexGrow: 1}}>Job Could Not Be Found</Box>
    }

    return <Box sx={{flexGrow: 1}}>
        <Header />
        {job.status.toLocaleLowerCase() === 'pending' ? <ApproveProofs /> : <ProofViewer />}
        <Footer />
    </Box>
}

export default JobDisplay;
