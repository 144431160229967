import {Alert, Box, CircularProgress} from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import {DownloadResponse, useJobContext} from '../context/job-context';
import {saveAs} from 'file-saver';
import {useEffect} from "react";

type Props = {
    proofId: number;
}

const DownloadButton = ({proofId}: Props) => {
    const {downloadJob} = useJobContext();
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState<DownloadResponse>();

    const handleDownload = async () => {
        if (response && response.file) {
            saveAs(response.file, response.file);
        }
    };

    useEffect(() => {
        const getJob = async (proofId: number) => {
            setLoading(true);
            const response = await downloadJob(proofId);

            if (response.error) {
                setErrorMessage(response.error);
            } else if (response.file) {
                setResponse(response)
            }

            setLoading(false);
        }

        getJob(proofId);
    }, [])

    return (
        <Box style={{minHeight: '70px'}}>
            {errorMessage === '' && !loading && <Button
                variant="contained"
                onClick={handleDownload}
                sx={{my: 2, color: 'white', display: 'block', width: '125px'}}
            >
                Download
            </Button>}
            {errorMessage !== '' && <Alert sx={{my: 2}} severity="warning">{errorMessage}</Alert>}
            {loading && <CircularProgress size='25px' sx={{my: 2}} />}
        </Box>
    );
}

export default DownloadButton;
