import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import React from "react";
import {useJobContext} from "../context/job-context";

const Footer = () => {
    const {job} = useJobContext();

    return (
        <React.Fragment>
            <AppBar position="fixed" color="primary" sx={{top: "auto", bottom: 0}}>
                <Toolbar sx={{display: 'flex', flexDirection: {xs: 'column', lg: 'row'}, justifyContent: 'center'}}>
                    {job && job.footerArray && job.footerArray.map((footerElement, index) => {
                        return <Box key={`footer-element-${index}`}>
                            <Box sx={{px: 1, display: {xs: 'none', lg: 'inline'}}}>{(index !== 0 ? "•" : "")}</Box>
                            <Typography noWrap={true} className="footerText" display={'inline'} dangerouslySetInnerHTML={{__html: footerElement}}></Typography>
                        </Box>
                    })}
                </Toolbar>
            </AppBar>
            <Toolbar key="spacer" />
        </React.Fragment>
    );
}

export default Footer;
